* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	height: 100%;
}

@font-face {
	font-family: sfui-regular;
	src: url('../../assets/fonts/sfuitext-regular.ttf');
}

@font-face {
	font-family: sfui-semibold;
	src: url('../../assets/fonts/sf-ui-display-semibold.ttf');
}

body {
	background: #fff;
	color: #434343!important;
	font-size: 15px;
	line-height: 1.65;
	overflow-x: hidden;
	min-height: 100%;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	overflow-y: auto;
	height: 100%;
	appearance: none;
	font-family: "sfui-regular";
	/* font-family:"Paypalsans" ; */
}

a {
	color: #434343;
	text-decoration: none;
}

a:focus,
a:hover {
	text-decoration: none;
}

*,
input,
select,
textarea,
option,
button {
	outline: none!important;
}

ul {
	list-style: none;
}

.w50 {
	width: 50%;
	float: left;
	padding: 10px 0 0;
}

.grid-container {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 33% 33% 32%;
}

.grid-container-two {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 64% 33%;
}

.grid-box {
	padding: 10px 0 0;
}

.grid-payment {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 28% 45% 25%;
	width: 100%;
}

.grid-date {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: auto 150px auto;
	width: 100%;
}

.grid-payment-box {
	border: 1px solid #cacbca;
	border-bottom: 5px solid #0d7836;
	border-radius: 4px;
	position: relative;
}

.grid-two {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 49% 49%;
	width: 100%;
}

.grid-two-box {
	padding: 10px 0 0;
}

.grid-one-third {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 66% 33%;
}

.grid-two-box form {
	padding: 0 12px 0 0;
}

.grid-third {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 32% 32% 32%;
}

.grid-modal-container {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 42% 42% 20%;
}

.grid-modal-box {
	padding: 10px 0 0;
}

.profile_container {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 15% 41% 41%;
}

.profile_box {
	padding-right: 10px;
	border-right: 1px solid #9d9d9d;
}

.profile_box:last-child {
	border-right: 0;
}

.dropdown-menu-top li {
	margin-top: 0 !important;
}

img.logo {
	width: 172px;
}

.mid-section p {
	text-align: justify;
}

.login_nav {
	display: block !important;
	float: right;
	padding: 0 !important;
}

.visatype_box {
	padding: 20px 0;
}

.catgories_box {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.09), 0 4px 6px rgba(0, 0, 0, 0.09);
	padding: 17px;
}

.eligible_box {
	width: 100%;
	float: left;
	padding-bottom: 60px;
}

.catgories h3,
.eligible_box h3 {
	font-size: 18px;
	padding-bottom: 12px;
}

.eligible_box input {
	float: right;
}

.catgories .save_btn {
	margin: 20px 0 !important;
}

.dropdown-menu-top li {
	margin-top: 0 !important;
}

nav {
	position: sticky;
	top: 0;
	z-index: 999;
	width: 100%;
	float: left;
	background: #fff;
	padding-top: 5px;
	border-bottom: 1px solid #dfdfdf;
}

.nav-links {
	list-style: none;
	width: 100%;
	text-transform: capitalize;
	text-align: right;
	margin-top: 14px;
	display: block;
}

.nav-links li a {
	text-decoration: none;
}

.nav-links img {
	width: 19px;
}

.nav-links li a:hover {
	color: #0d7836;
}

.nav-links li {
	position: relative;
	display: inline-block;
	margin-left: 15px;
}

.dash img {
	width: 14px;
	height: auto;
}

.nav-links li a:hover::before {
	width: 80%;
}

.adjust-height {
	min-height: 600px;
}

.header_box {
	display: inline-block;
}

.menu {
	float: right;
	width: auto;
}

.dropdown a:hover {
	color: #fff !important;
}

.fix_height {
	height: 140px;
}

.dropdown {
	width: auto;
	position: relative;
	margin-top: 0 !important;
}

ul li ul.dropdown-menu-top {
	width: 100%;
	background: #fff;
	display: none;
	position: absolute;
	z-index: 999;
	text-align: center;
	border: 1px solid #0D7836;
	float: left;
	/* box-shadow: 0 14px 28px rgb(0 0 0/9%),0 10px 10px rgb(0 0 0/9%); */
}

ul li ul.dropdown-menu-top li {
	display: block;
	margin: 3px 0 !important;
}

.header_box span {
	position: relative;
	top: -20px;
	padding-left: 9px;
	font-size: 13px;
	font-family: "sfui-semibold";
}

.header_box img {
	width: auto;
}

hr.header_line {
	width: 0;
	margin: 0 auto;
	color: #CACBCA;
	border-width: 0;
	background-color: #CACBCA;
	display: inline-block;
	height: 50px;
	border-left: 2px dotted #fff;
}

.email-label {
	width: 100%;
	float: left;
	padding: 5px 0 13px;
}

.email-label label {
	width: auto;
	font-size: 13px;
}

.email-label span {
	width: auto;
	float: left;
	line-height: normal;
	padding-left: 3px;
}

.profile_type .form_box {
	height: 65px;
}

.profile_type .grid-two-box {
	padding: 0 !important;
}

.dropdown-menu-top li:after {
	content: "";
	display: block;
	height: 1px;
	width: 100%;
	margin: 2px 0;
	background: #0d7836;
}

.dropdown .custom_btn {
	margin: 10px 10px 0 0;
	padding: 6px 6px !important;
}

.dropdown-menu-top li:last-child:after {
	background: none;
	margin: 2px 0;
}

.custom_btn1 {
	background: #0d7836;
	color: #fff;
	padding: 5px 8px;
	border-radius: 2px;
	width: auto;
	display: block;
	border: none;
	border-radius: 3px;
	appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.track_btn{background: #f38249;
color: #fff !important;
padding: 5px 8px;
border-radius: 2px;
width: auto;
display: block;
border: none;
border-radius: 3px;
outline:none;
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
}
.track_btn a{color:#fff;}
.custom_btn,
.btn {
	background: #0D7836;
	padding: 7px 20px !important;
	color: #fff;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #0D7836;
	cursor: pointer;
	margin: 10px 5px;
	appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.custom_btn:hover,
.btn:hover {
	background: #10572B;
	color: #FFF;
}

.back_btn {
	padding: 7px 20px !important;
	cursor: pointer;
	width: auto !important;
	background: #636363;
	color: #f5f5f5;
	border-radius: 3px;
	border: 1px solid #636363;
	margin: 10px 5px 10px 0;
	appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.back_btn:hover {
	background: #686868;
	color: #fff;
}

.save_btn {
	background: #F96922;
	padding: 7px 20px !important;
	color: #fff;
	cursor: pointer;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #F96922;
	margin-right: 10px;
	margin: 10px 5px;
	appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.save_btn:hover {
	background: #DF692F;
	color: #fff;
}

.edit_btn {
	background: #E8BA4E;
	padding: 7px 20px !important;
	color: #fff;
	cursor: pointer;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #DFB146;
	margin-right: 10px;
	margin: 10px 5px;
	appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.edit_btn:hover {
	background: #F0B11C;
	color: #fff;
}

.receipt_btn {
	background: #ff8d00;
	padding: 7px 20px !important;
	color: #fff;
	cursor: pointer;
	border-radius: 3px;
	width: auto !important;
	border: 1px solid #DFB146;
	margin-right: 10px;
	margin: 10px 5px;
	border-color: #d77a07;
	appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.receipt_btn:hover {
	background: #BD880B;
	color: #fff;
}

.main {
	width: 90%;
	padding-right: 44px;
	padding-left: 44px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	padding-bottom: 30px;
}

.main1 {
	width: 90%;
	padding-right: 44px;
	padding-left: 44px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 30px;
	padding-bottom: 30px;
}

:focus {
	outline: none;
}

#msform {
	position: relative;
	padding: 8px 15px;
}

.home h4 {
	padding-bottom: 15px;
	color: #0d7836;
}

.instruction_box ul li {
	list-style-type: disc;
	text-align: left;
	margin-left: 16px;
	padding-top: 3px;
}

.form_main fieldset {
	background: white;
	border: 1px solid #cacbca;
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 90px;
	position: relative;
	text-align: left;
	padding: 0;
	/* display: none */
}

fieldset.plr10 {
	padding: 5px 15px 15px;
	margin-bottom: 10px;
}

.step_info {
	background: #ebebeb;
	padding: 3px 13px;
	border-bottom: 1px solid #cacbca;
}

.form_main {
	width: 100%;
	float: left;
}

.form-card {
	text-align: left
}

#progressbar {
	width: 100%;
	float: left;
	color: lightgrey;
	margin-bottom: 20px;
}

#progressbar .active {
	color: #515151;
	font-family: "sfui-semibold";
}

#progressbar li {
	list-style-type: none;
	font-size: 14px;
	width: 25%;
	float: left;
	position: relative;
	font-weight: 400;
	text-align: center;
}

#progressbar #passport-type:before {
	content: "1";
}

#progressbar #passport-type-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #fff;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #passport-type-tick p {
	font-family: "sfui-regular";
}

#progressbar #personal:before {
	content: "2"
}

#progressbar #personal-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #personal-tick p {
	font-family: "sfui-regular";
}

#progressbar #contact:before {
	content: "3";
}

#progressbar #contact-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #contact-tick p {
	font-family: "sfui-regular";
}

#progressbar #kin:before {
	content: "4";
}

#progressbar #kin-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #kin-tick p {
	font-family: "sfui-regular";
}

#progressbar #processing-center:before {
	content: "5";
}

#progressbar #processing-center-tick:before {
	content: '\2713';
	border: 1px solid #0d7836;
	background: #f0fff5;
	color: #0d7836;
	font-size: 20px;
}

#progressbar #processing-center-tick p {
	font-family: "sfui-regular";
}

#progressbar li:before {
	width: 30px;
	height: 30px;
	line-height: 30px;
	display: block;
	font-size: 15px;
	/* color: #0d7836; */
	background: #fff;
	border-radius: 50%;
	margin: 7px auto 2px auto;
	padding: 2px;
	border: 1px solid rgb(13 120 54 / 47%);
	font-family: "sfui-semibold";
	text-align: center;
}

#progressbar li:after {
	content: '';
	width: 100%;
	height: 5px;
	background: rgb(13 120 54 / 47%);
	position: absolute;
	left: 0;
	top: 25px;
	z-index: -1
}

#progressbar li.active::before {
	border: 1px solid #0d7836;
	background: #097734;
	color: #fff;
}

#progressbar li.active:after {
	background: #0d7836
}

label {
	width: 100%;
	float: left;
	text-align: left;
	font-size: 13px;
	font-family: "sfui-semibold";
}

.medatory {
	color: #ee6969;
}

input,
select,
textarea {
	width: 100%;
	border: 1px solid #cacbca;
	height: 36px;
	padding: 0 10px;
	box-sizing: border-box;
	font-size: 14px;
}

.textbox {
	width: 100%;
	float: left;
	height: 150px;
}

.info {
	padding-bottom: 10px;
	width: 100%;
	float: left;
}

select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	border-radius: 0;
	background: none;
}

.icon_caret {
	width: auto;
	float: right;
	position: absolute;
	right: 7px;
	top: 6px;
	color: rgba(0,0,0,.54);
}

.mat-select {
	height: 36px;
}

.input_box {
	position: relative;
	width: 100%;
	float: left;
	text-align: left;
}

.instruction_box {
	border: 1px solid #cacbca;
	padding: 5px 20px 10px;
	text-align: justify;
	margin-top: 21px;
}

.instruction_box h4 {
	font-size: 20px;
	color: #0d7836;
}

.fee span {
	width: 100%;
	float: left;
	text-align: center;
}

.focus_border:focus {
	border: 2px solid #4caf50;
	transition: 0.1s;
}

.focus_border {
	color: #212121!important;
}

.effect {
	border: 1px solid #cacbca;
	padding: 7px 4px 9px;
	transition: 0.4s;
	color: #434343!important;
}

.effect~.focus-border:before,
.effect~.focus-border:after {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 2px;
	background-color: #4caf50;
	transition: 0.4s;
}

.effect~.focus-border:after {
	top: auto;
	bottom: 0;
}

.effect~.focus-border i:before,
.effect~.focus-border i:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	width: 2px;
	height: 0;
	background-color: #4caf50;
	transition: 0.6s;
}

.effect~.focus-border i:after {
	left: auto;
	right: 0;
}

.effect:focus~.focus-border:before,
.effect:focus~.focus-border:after {
	left: 0;
	width: 100%;
	transition: 0.4s;
}

.effect:focus~.focus-border i:before,
.effect:focus~.focus-border i:after {
	top: 0;
	height: 100%;
	transition: 0.6s;
}

footer {
	background: #ebebeb;
	width: 100%;
	float: left;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 999;
	padding-top: 5px;
	border-top: 1px solid #d2d2d2;
}

.footer p {
	text-align: left;
}

.view-buttons {
	width: 100%;
	float: left;
	margin-bottom: 60px;
}

.img_upload {
	height: 235px;
	padding: 20px;
	text-align: center;
}

.img_box {
	width: 100%;
	float: left;
}

.img_box img {
	width: auto;
	border: 1px solid #cacbca;
	height: 190px;
	max-width: 180px;
	margin-bottom: 10px;
	object-fit: cover;
}

.img_upload label {
	width: 100%;
	float: left;
	text-align: center;
}

span.icon_image {
	position: relative;
}

span.icon_image i {
	border: 1px solid #cacbca;
	border-radius: 5px;
	width: 30px;
	height: 27px;
	padding: 7px;
	float: left;
	position: absolute;
	right: 6px;
	background: #fff;
	top: -29px;
	text-align: center;
}

input.preview_btn {
	width: auto;
	padding: 6px 10px;
	height: auto;
	border: 1px solid #0d7836;
	border-radius: 3px;
	background: #0d7836;
	color: #fff;
	line-height: 14px;
	margin-right: 8px;
	cursor: pointer;
	appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.new_app {
	position: relative;
	width: 100%;
	text-align: right;
	bottom: -4px;
	float: left;
}

.new_app .save_btn,
.new_app .custom_btn {
	margin: 0 3px!important;
	position: relative;
	z-index: 99;

}

.alert {
	width: 100%;
	float: left;
	text-align: left;
	color: red;
	font-size: 0.81em;
	line-height: 10px;
	padding-top: 3px;
}

.form_box {
	width: 100%;
	float: left;
	height: 60px;
	font-size: 15px;
}

.banner-img img {
	width: 100%;
}

.ptb20 {
	padding: 10px 0 15px;
}

.personal.detail h3 {
	font-size: 14px;
	line-height: 19px;
	font-size-adjust: 0.6;
}

.ques_box {
	padding: 10px 0;
}

.ques_box input {
	width: auto;
	display: inline-block;
	height: auto;
}

.ques_box label {
	width: auto;
	padding-right: 11px;
}

span.tooltip {
	display: none;
	float: left;
	background-color: rgba(121, 121, 121, 0.93);
	color: #fff;
	padding: 3px 10px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	width: 250px;
	font-size: 12px;
	position: absolute;
	margin: 0 0 0 10px;
	z-index: 999;
	top: 42px;
	left: 0;
	text-align: left;
}

.center_body h6 {
	text-align: left;
	font-size: 16px;
	padding: 10px 0;
}

span.tooltipArrow {
	content: ' ';
	height: 0;
	position: absolute;
	width: 0;
	border: 7px solid transparent;
	border-bottom-color: rgba(121, 121, 121, 0.93);
	top: -14px;
	left: 12px;
}

table {
	width: 100%;
	border: 1px solid #cacbca;
	border-collapse: collapse;
	border-spacing: 0;
	margin-bottom: 20px;
	overflow-y: auto;
	overflow-x: hidden;
}

tbody {
	display: block;
	height: auto;
	overflow-y: auto;
	max-height: 360px;
}

thead {
	table-layout: fixed;
	display: table;
	width: 100%;
	position: sticky;
	top: 0;
}

tr {
	display: table;
	table-layout: fixed;
	width: 100%;
	float: left;
	border-bottom: 1px solid #cacbca;
}

th {
	padding: 0 5px 0;
	color: rgb(10, 90, 30);
	background: #d7eaca;
	font-family: "sfui-semibold";
	height: 36px;
	text-align: left;
	font-weight: normal;
	table-layout: fixed;
	border-bottom: 1px solid #cacbca;
}

td {
	padding: 0 5px;
	color: #183247;
	height: 36px;
	text-align: left;
	border-left: 1px solid #ddd;
	text-overflow: ellipsis;
	overflow: hidden;
	table-layout: fixed;
}

table tr:last-child {
	border-bottom: 0;
}

.dash th,
.dash td {
	white-space: nowrap;
}

table input[type="checkbox"] {
	width: auto;
	height: auto;
	padding: 5px !important;
}

textarea {
	width: 100%;
	height: 111px;
	resize: none;
	font-family: "sfui-regular";
	font-size: 14px;
	float: left;
}

.textbox_profile {
	margin: 12px 0 55px;
	width: 100%;
	float: left;
}

.input_profile {
	margin: 12px 0 0;
	width: 100%;
	float: left;
}

.p-info fieldset {
	background: #f7f7f7;
	margin-bottom: 30px;
}

.p-info {
	width: 100%;
	float: left;
}

legend {
	padding: 0px 3px;
	color: #0d7836;
	font-family: "sfui-semibold";
	font-size: 14px;
	text-align: left;
}

.t_and_c {
	width: 100%;
	float: left;
	padding: 10px 0;
	height: 55px;
}

.t_and_c input {
	width: auto;
	height: auto;
}

.cancel-btn {
	background: #ddd;
	border: 1px solid #ddd;
	color: #8a8787;
	appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

i {
	cursor: pointer;
}

.cancel-btn:hover {
	background: #fff;
}


/* Login Page CSS Start */

.home {
	width: 100%;
	float: left;
}


.banner_text {
	width: 100%;
	float: left;
	color: #fff;
	position: absolute;
	top: 35%;
	left: 8%;
	line-height: 42px;
}

.banner_text hr {
	border: 1px solid #f96922;
	width: 40px;
	margin: 10px 0;
}

.top_head img {
	width: 38px;
	height: auto;
	display: inline-block;
}

.top_head {
	background: #F96922;
	padding: 5px 40px;
	text-align: left;
	margin-top: -66px;
	position: relative;
}

.main-bottom {
	background: #F5F5F5;
	width: 100%;
	text-align: center;
	margin-bottom: 40px;
	height: 120px;
	position: relative;
	box-shadow: 0 14px 28px rgb(0 0 0 / 9%), 0 10px 10px rgb(0 0 0 / 9%);
}

.top_head span {
	width: auto;
	position: relative;
	display: inline-block;
	color: #fff;
	font-family: "sfui-semibold";
	left: 10px;
}

.top_head div {
	width: 100%;
	width: 100%;
	float: left;
	position: absolute;
	top: 28px;
	left: 26%;
	color: #fff;
	font-family: "sfui-semibold";
}

button {
	padding: 7px 20px;
	margin-top: 10px;
	background: #0D7836;
	border: none;
	position: relative;
	color: #fff;
	font-family: 'sfui-semibold';
	border-radius: 3px;
	appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button img {
	width: 18px;
	position: absolute;
	left: 16px;
	top: 8px;
}

.main-bottom p {
	text-align: justify;
	padding: 10px 20px 0;
}

.img_num img {
	float: right;
	position: absolute;
	right: 0;
	bottom: 0;
}

.loginBtn {
	cursor: pointer;
}

.dash.shop-cart i {
	cursor: default;
}


/* Login Page CSS End */

.disable {
	cursor: not-allowed;
	background: #ccc !important;
	border: 1px solid #ccc;
	color: #9d9d9d;
}

.dropdown-menu-top a:hover {
	color: #000 !important;
}

input:disabled {
	background-color: #d8d8d8 !important;
	cursor: not-allowed;
	border: 1px solid #b9b9b9;
	color: #8e8e8e !important;
}


/* ........ DASHBOARD ............  */

.bottom_span {
	width: 100%;
	float: left;
	border-bottom: 1px solid #f3f3f3;
	padding-bottom: 10px;
}

table.dash {
	margin-bottom: 0;
	height: 160px;
	margin-top: 20px;
}

.dash th {
	background: #0D7836;
	color: #fff;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 4;
	font-weight: normal;
}

tr.cus_row td {
	width: 50%;
	text-align: center;
	border: none;
}

.cus_row {
	margin: 20px 0;
}

.info-covid {
	background: #FBD0D0;
	color: #AC3939;
	padding: 6px 13px;
	width: 100%;
	font-size: 11px;
	/* letter-spacing: 1px; */
	margin-bottom: 16px;
	text-transform: capitalize;
}

.banner-img-name {
	width: 100%;
	background: #0d7836;
	color: #fff;
 margin-bottom: 7px;
	text-align: left;
	float: left;
	padding: 4px 0;
}


.user_type,
.profile_type {
	text-align: center;
	margin: 0 auto;
	width: 50%;
}

.line_hr {
	width: 90%;
	margin: 0 auto;
	color: #CACBCA;
	height: 1px;
	border-width: 0;
	background-color: #CACBCA;
	display: inline-block;
}

.profile_type .custom_btn {
	margin: 10px 0;
}

.user_type ul li {
	display: block;
	position: relative;
	float: left;
	width: 100%;
}

.user_type ul li input[type=radio] {
	position: absolute;
	visibility: hidden;
}

.user_type ul li label {
	font-size: 14px;
	font-family: "sfui-regular";
	display: block;
	position: relative;
	padding: 14px 30px 5px;
	z-index: 9;
	cursor: pointer;
	-webkit-transition: all 0.25s linear;
}

.user_type ul li .check {
	display: block;
	position: absolute;
	border: 1px solid #CACBCA;
	border-radius: 100%;
	height: 15px;
	width: 15px;
	top: 30px;
	left: 0;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

.user_type ul li .check::before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 100%;
	height: 7px;
	width: 7px;
	top: 3px;
	left: 3px;
	margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}

.user_type input[type=radio]:checked~.check {
	border: 1px solid #0D7836;
}

.user_type input[type=radio]:checked~.check::before {
	background: #0D7836;
}

.user_type input[type=radio]:checked~label {
	color: #0D7836;
}

.user_type span {
	width: 100%;
	float: left;
	font-size: 13px;
}

.tabset #tab1 {
	position: absolute;
	left: -100vw;
}

.tabset #tab2 {
	position: absolute;
	left: -150vw;
}

.tabset #tab3 {
	position: absolute;
	left: -90vw;
}

.tabset>input:first-child:checked~.tab-panels>.tab-panel:first-child,
.tabset>input:nth-child(3):checked~.tab-panels>.tab-panel:nth-child(2),
.tabset>input:nth-child(5):checked~.tab-panels>.tab-panel:nth-child(3),
.tabset>input:nth-child(7):checked~.tab-panels>.tab-panel:nth-child(4),
.tabset>input:nth-child(9):checked~.tab-panels>.tab-panel:nth-child(5),
.tabset>input:nth-child(11):checked~.tab-panels>.tab-panel:nth-child(6) {
	display: block;
}

.tabset>label {
	position: relative;
	display: inline-block;
	padding: 10px 15px;
	cursor: pointer;
	width: auto;
	float: none;
	font-size: 14px;
	color: #0000005d;
}

.tabset {
	width: 100%;
	float: left;
	position: relative;
}

.tabset>label:hover,
.tabset>input:focus+label {
	color: #0d7836;
}

.tabset>label:hover::after,
.tabset>input:focus+label::after,
.tabset>input:checked+label::after {
	background: #0d7836;
}

.tab-panel p {
	display: inline-block;
	padding-left: 20px;
}

.tabset>input:checked+label {
	border-color: #0d7836;
	border-bottom-color: rgb(13, 120, 54);
	border-bottom: 1px solid #0d7836;
	margin-bottom: 0;
	background: #0d7836;
	color: rgb(255, 255, 255);
}

.tab-panels {
	border-top: 1px solid #0d7836;
}

.tab-panel table {
	border-top: 0;
}

.step_info span {
	font-size: 16px;
	font-family: 'sfui-semibold';
}

.m40 {
	margin-bottom: 40px;
}

.w100 {
	width: 100%;
	float: left;
}

.search_grid {
	display: grid;
	grid-template-columns: 33% 2% 62%;
	grid-gap: 20px;
}

.profile_view ul {
	display: grid;
	align-items: center;
	grid-template-columns: 25% 25% 25% 24%;
	grid-gap: 8px;
	margin-bottom: 10px;
}

.profile_view li {
	padding-left: 45px;
}

.profile_view li:first-child {
	padding-left: 0;
}

.big_font {
	font-size: 20px;
}

.status_info img {
	width: 175px;
	text-align: right;
	float: right;
}

.grid_detail {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 49% 49%;
}

.grid_detail-box {
	padding: 0 10px;
}

.profile_view p {
	display: inline-block;
}

.fr {
	float: right;
}

.fr1 {
	float: right;
}

.grid_detail-box:first-child {
	border-right: 1px solid #9d9d9d;
}

.row_box {
	padding: 6px 0 0;
}

.total_box p {
	font-size: 19px;
}

.total_box label,
.total_box span {
	font-size: 22px;
}

.catagories_type {
	display: inline-block;
}

.catagories_type:last-child {
	padding-left: 50px;
}

.total_box {
	padding: 0 20px;
}

.main-footer {
	width: 90%;
	padding-right: 44px;
	padding-left: 44px;
	margin-right: auto;
	margin-left: auto;
}

fieldset {
	border: 1px solid #9d9d9d;
	padding: 0px 20px;
	margin: 20px 0 0;
}

.profile_view fieldset {
	padding: 0 20px 10px;
}

.notes {
	margin: 18px 0;
}

.payment_detail .grid-two-box {
	padding: 0;
}

.pd0 {
	padding: 0 !important;
}

.pd0 legend {
	margin: 0px 20px;
}

.pd12 {
	padding: 5px 20px 1px;
}

.modal-header i {
	text-align: right;
	width: 100%;
}

.modal-header h4 {
	width: 100%;
	float: left;
}

.modal-content {
	height: auto;
	top: 17%;
	max-width: 800px !important;
}

.tl {
	text-align: left !important;
	margin-left: 22px;
}

.notice_modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: left;
	padding: 0 !important;
	background: #fff;
	padding: 15px;
	border-radius: 6px;
	width: 100%;
	max-width: 35% !important;
}
.track_application {
    display: none;
}

/* payment page css  */

.grid-payment-box h4 {
	background: #f96922;
	color: #fff;
	padding: 5px 10px;
	border: 1px solid #f96922;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.pd20 {
	padding: 10px 10px;
}

.personal.detail span {
	width: 100%;
	float: left;
	font-size: 12px;
	line-height: 18px;
	padding-left: 16px;
	font-family: 'sfui-semibold';
}

.personal.detail {
	float: left;
	width: 75%;
}

.personal.detail i {
	float: left;
	width: 15px;
	padding-top: 2px;
	color: red;
}

.fee_detail {
	width: 25%;
	float: left;
	text-align: right;
}

.total_fee {
	position: absolute;
	bottom: 0;
	width: 100%;
	float: left;
	padding: 4px 10px;
	background: #0d7836;
	color: #fff;
}

.cart-info {
	width: 100%;
	float: left;
	position: relative;
	height: 370px;
	overflow-y: auto;
	box-shadow: 0 1px 4px #f4f4f4;
}

.grid-payment-box h5 {
	font-size: 17px;
}

.fee {
	padding: 10px;
	border: 1px solid #f7f7f7;
	width: 100%;
	float: left;
	background: #f9f9f9;
	border: 1px solid #0d7836;
}

.fee div {
	width: 100%;
	float: left;
	display: block;
	text-align: left;
	border-bottom: 1px solid #eeebeb;
	padding: 6px 0;
}

.pay_input input {
	padding: 3px 0;
}

.head_table {
	width: auto;
	float: left;
	text-align: left;
	font-size: 18px;
	margin-bottom: 10px;
}

.change_btn {
	margin: 0;
	float: right;
}

.fee span {
	width: auto;
	float: right;
	text-align: center;
	display: block;
}

.center_body {
	text-align: center;
	padding: 40px 0;
	width: 100%;
	float: left;
	margin-bottom: 50px;
}

.card h1 {
	color: #0d7836;
	font-family: "sfui-semibold";
	font-size: 25px;
	margin-bottom: 10px;
}

.card p {
	color: #404F5E;
	font-size: 15px;
	margin: 20px 0;
}

.card i {
	color: #0d7836;
	font-size: 60px;
	line-height: 100px;
	margin-left: -10px;
}

.card {
	background: white;
	padding: 20px 30px;
	border-radius: 4px;
	box-shadow: 0px 2px 8px #C8D0D8;
	display: inline-block;
	margin: 0 auto;
	width: 800px;
}

.info_paid {
	border: 1px solid #ddd;
	width: 100%;
	margin-bottom: 20px;
	float: left;
}

.info_paid span {
	width: 100%;
	float: left;
	text-align: right;
	padding: 6px;
	border-bottom: 1px solid #ddd;
}

.info_paid span:last-child {
	border-bottom: 0;
}

.info_paid span b {
	text-align: right;
	width: auto;
	float: left;
}

.cardimg {
	position: absolute;
	width: 40px;
	top: 10px;
	right: 7px;
}

.modal {
	z-index: 9999 !important;
	background: rgba(0, 0, 0, .7) !important;
}

.modal table {
	margin: 0 !important;
	height: auto;
	width: auto !important;
}

.confirmbox-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 30% !important;
	padding: 0 !important;
	background: #fff;
	padding: 15px;
	border-radius: 6px;
}

.confirmbox-container h3 {
	padding: 6px 10px 5px !important;
	margin: 10px 0 !important;
	border-bottom: 1px solid #0d7836 !important;
}

.confirmbox-container p {
	margin: 0 !important;
	padding: 5px !important;
}

.confirmbox-container .modal-body p span {
	color: red;
}

.btn-grp button {
	margin: 0 7px 20px !important;
}

.card table i {
	font-size: 16px;
	line-height: normal;
}

.card table {
	height: auto;
}

.filter_box {
	width: 20%;
	float: left;
	margin-right: 10px;
}

.change_request {
	width: 100%;
	float: left;
	background: #f4f4f4;
	padding: 10px;
	margin-bottom: 20px;
}

.change_table {
	height: auto;
}

.change_request .save_btn {
	margin: 18px 0 0;
	width: 95px !important;
}

.detail_change_view {
	padding: 10px;
}

.detail_change_view table {
	margin-bottom: 0;
}

.field__input:focus::-webkit-input-placeholder,
.field__input:focus::-moz-placeholder {
	color: var(--uiFieldPlaceholderColor);
}

.field {
	border-width: 1px;
	display: inline-flex;
	position: relative;
	font-size: 1rem;
}

.field__input {
	height: 38px;
	padding: 0.25rem 1rem 0.15em;
	--uiFieldPlaceholderColor: var(--fieldPlaceholderColor, #767676);
}

.field__input::-webkit-input-placeholder {
	opacity: 0;
	transition: opacity .2s ease-out;
}

.field__input::-moz-placeholder {
	opacity: 0;
	transition: opacity .2s ease-out;
}

.field__input:focus::-webkit-input-placeholder {
	opacity: 1;
	transition-delay: .2s;
}

.field__input:focus::-moz-placeholder {
	opacity: 1;
	transition-delay: .2s;
}

.field__label-wrap {
	box-sizing: border-box;
	pointer-events: none;
	cursor: text;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.field__label-wrap::after {
	content: "";
	box-sizing: border-box;
	width: 100%;
	height: 0;
	opacity: 0;
	position: absolute;
	bottom: 0;
	left: 0;
}

.field__input:focus~.field__label-wrap::after {
	opacity: 1;
}

.field__label {
	position: absolute;
	left: var(--uiFieldPaddingLeft);
	top: calc(50% - .5em);
	padding: 0 1rem;
	line-height: 1;
	font-size: 14px;
}

.field__input:focus~.field__label-wrap .field__label,
.field__input:not(:placeholder-shown)~.field__label-wrap .field__label {
	font-size: 11px !important;
	top: var(--fieldHintTopHover, .25rem);
}

.dashbox {
	width: 100%;
	float: left;
}

.box_dashboard {
	border: 1px solid #0d7836;
	padding: 8px 10px;
	width: 100%;
	float: left;
	height: 100px;
}

.box_dashboard img {
	display: block;
	width: 60px;
	float: left;
}

.box_dashboard:hover {
	background: #f1fbeb;
	color: #0d7836;
}

.box_dashboard a {
	width: 100%;
	float: left;
}

.upload_icon {
	padding-top: 16px;
}

.dash_info {
	width: auto;
	padding: 5px 12px;
	text-align: left;
	margin-left: 10px;
	display: table;
}

.important_instructions {
	width: 100%;
	float: left;
	border-top: 1px solid #cacbca;
	margin: 30px 0;
}

.important_instructions li {
	list-style-type: disc;
	list-style-position: inside;
}

.important_instructions h3 {
	margin: 10px 0;
}

.dropdown-toggle::after {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
	margin-left: 0;
}

.phone_input {
	height: 36px;
	width: 100%;
	float: left;
}

#phone {
	height: 20px;
	border: 0 !important;
	padding-left: 88px !important;
}

.iti__selected-flag {
	width: 80px !important;
}

.grid-history {
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 40% 60%;
	width: 100%;
	float: left;
}

.history_box {
	border: 1px solid #cacbca;
	width: 100%;
	float: left;
}

.history_box1 {
	border: 1px solid #cacbca;
	border-bottom: 0;
	width: 100%;
	float: left;
}

.green-band {
	background: #d7eaca;
	padding: 8px 8px 5px;
}

.history_box ul li {
	padding: 7px 10px;
	border-bottom: 1px solid #ddd;
}

.history_box ul li span {
	display: inline-block;
}

span.align-right {
	float: right;
}

.request_counts {
	margin-bottom: 18px;
}

.request_counts ul li {
	display: inline-block;
	width: 24%;
	border: 0;
	background: #fff;
	box-shadow: 0 0px 10px rgba(0, 0, 0, 0.09), 0 5px 6px rgba(0, 0, 0, 0.09);
	margin-right: 10px;
	padding: 15px 12px 10px;
}

.request_counts ul li:last-child {
	margin-right: 0;
}

.history_box1 table tr:last-child {
	border-bottom: 1px solid #cacbca !important;
}

.request_counts h4 {
	font-size: 18px;
	text-align: center;
}

.blue {
	background: #AFDFF5;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #A0B6E3;
	border-radius: 50%;
}

.lightgreen {
	background: #91E8B2;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #69CB8E;
	border-radius: 50%;
}

.lightorange {
	background: #EBD89B;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #E6C148;
	border-radius: 50%;
}

.re-red {
	background: #F88490;
	width: 30px;
	height: 30px;
	color: white;
	border: 1px solid #F88499;
	border-radius: 50%;
}

.history_box h4 {
	color: #0d7836;
}

.history_box1 table th {
	background: #f5f5f5;
	border-top: 1px solid #d5d5d5;
	border-bottom: 1px solid #d5d5d5;
	color: #353434;
	font-size: 12px;
}

.history_box1 table {
	width: 100%;
	border: 0;
	margin: 0;
}

.history_box .save_btn {
	float: right;
	text-align: right;
	width: 100%;
	margin: 13px 10px;
	height: 30px;
	padding: 4px 7px !important;
	border-radius: 4px;
}

.success {
	background-color: #f4fff4;
	color: #1db124;
	border: 1px solid #c7ffc3;
	font-size: 12px;
	display: inline-block;
	width: auto;
	border-radius: 2px;
	padding: 2px 4px;
}

.danger {
	background-color: #fff4f4;
	color: #ff2525;
	border: 1px solid #ffd0d0;
	font-size: 12px;
	display: inline-block;
	width: auto;
	border-radius: 2px;
	padding: 2px 4px;
}

.warning {
	background-color: #fffbeb;
	color: #D7A226;
	border: 1px solid #F9EEE1;
	font-size: 12px;
	display: inline-block;
	width: auto;
	border-radius: 2px;
	padding: 2px 4px;
}

.footer_flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

footer img {
	width: auto;
	padding-bottom: 4px;
	height: auto;
}

.section_doc {
	background: #f1f1f1;
	padding: 10px 20px;
}

.image_upload_container {
	width: 870px !important;
	padding: 0 !important;
	background: #fff;
	border-radius: 6px;
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.image_upload_container .modal-body {
	height: 480px;
	margin-bottom: 20px;
	overflow-y: auto;
}

.source-image {
	width: auto;
	height: auto;
}

.image_upload_container .centered {
	position: relative;
	margin: 0 auto;
}

.icon_dummy i {
	font-size: 70px;
	opacity: .4;
	padding: 20px;
}

input.readonly {
	background-color: #ebebeb;
}

.notification {
	border: 1px solid #cf0d0d;
	width: 100%;
	float: left;
	padding: 10px;
	margin-bottom: 20px;
}

.notice_board {
	display: flex;
	color: #d65050;
}

.notice_board i {
	font-size: 26px;
	color: #cf0d0d;
}

.message_board {
	padding-left: 13px;
}

textarea+.tooltip {
	top: 117px;
}

.fieldlabels.label_doc {
	line-height: 13px;
	height: 30px;
}

.confirmbox-container .modal-header,
.confirmbox-container .modal-body,
.confirmbox-container .modal-footer {
	text-align: left !important;
}

.profile-view {
	border-radius: 4px;
	width: 100%;
	background: #fff;
	float: left;
}

.s-head {
	border: 1px solid #979797;
	background: #fff;
	/* border-radius: 3px; */
	/* margin-top:40px; */
	z-index: 999;
}

.s-head .result-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.s-head .result-header .post-img {
	width: 200px;
	height: 170px;
	overflow: hidden;
	object-fit: fill;
}

.s-head .result-header .post-img img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.s-head .result-header .user-info-detail {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.s-head .result-header .user-info-detail p {
	width: 100%;
	float: left;
	text-align: justify;
}

.profile-view .s-content {
	border-radius: 0 0 4px 4px;
}

.profile-view .s-content ul {
	list-style-type: disc;
	margin: 0 32px 12px;
}

.profile-view .s-content .mb-12 {
	margin-bottom: 12px;
}

.profile-view .s-content .profile-table {
	width: 100%;
	text-align: left;
	line-height: 3em;
	border-collapse: collapse;
	margin-bottom: 24px;
	font-size: 14px;
	vertical-align: top;
}

.profile-view .s-content .profile-table thead {
	border-bottom: 1px solid #F8911C;
	text-align: left;
}

.profile-view .s-content .profile-table thead th {
	padding: 0 16px;
	height: 44px;
	font-weight: 600;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 9;
	background: #fff;
	-webkit-box-shadow: 0 1px 1px #ddd;
	box-shadow: 0 1px 1px #ddd;
}

.profile-view .s-content .profile-table tbody tr:nth-child(even) {
	background: #f6f6f6;
}

.profile-view .s-content .profile-table tbody tr td {
	color: #1c1d1f;
	height: 40px;
	padding-left: 16px;
}

.sr-p-info {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: auto auto;
	grid-template-columns: 50% 50%;
	line-height: 10px;
}

.sr-p-info div {
	border-bottom: 1px solid #aeaeae;
	border-right: 1px solid #aeaeae;
	padding: 6px 10px;
}

.sr-p-info div:last-child {
	border-bottom: 0;
}

.sr-p-info div:nth-last-child(2) {
	border-bottom: 0 !important;
}

.sr-p-info div:nth-child(even) {
	border-right: 0 !important;
}

.header_logo {
	border-bottom: 1px solid #cacbca;
}

.header_logo span {
	position: relative;
	top: -20px;
	padding-left: 9px;
	font-size: 14px;
}

.p-body {
	font-size: 16px;
	font-family: "sfui-semibold";
	margin: 0;
}

.barcode img {
	width: 250px;
	height: 80px;
}

.fw-500 {
	font-weight: 600;
}

.line {
	border-bottom: 1px solid #979797;
	padding: 0px 10px;
	background: #f1f1f1;
}

.sr-p-info p {
	width: 100%;
	float: left;
	font-family: "sfui-regular";
	margin: 4px 0 0;
	line-height: 1.35;
	opacity: .88;
	text-overflow: ellipsis;
	/* text-transform: capitalize; */
	overflow: hidden;
}

.total_box {
	font-size: 19px;
	font-family: "sfui-regular";
	padding-top: 5px;
}

.upper_bar,
.common_view {
	display: flex;
	justify-content: space-between;
	padding: 0 15px;
}

.border_line {
	border: 0.5px solid #979797;
	margin-top: 10px;
}

.half_col {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.half_col_box {
	width: 50%;
	border: 1px solid #979797;
}

.tab50 div {
	padding: 2px 10px;
	border-bottom: 1px solid #d7d7d7;
	width: 100%;
	float: left;
}

.tab50 label {
	width: auto;
}

.form-card {
	text-align: left;
	padding: 8px 15px;
}

.card_box_pass:hover {
	border: 1px solid green;
	box-shadow: 0 5px 10px rgb(0 0 0 / 9%), 0 8px 7px rgb(0 0 0 / 9%);
}

.grid-passport {
	display: grid;
	grid-template-columns: 66% 33%;
	grid-gap: 14px;
}

.passport_type input[type="radio"]+label {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding-left: 22px;
	margin-right: 20px;
	font-size: 13px;
	width: auto;
	line-height: 11px;
}

.passport_type input[type="radio"]+label:before {
	content: "";
	display: block;
	width: 12px;
	height: 12px;
	margin-right: 14px;
	position: absolute;
	top: -3px;
	left: 0;
	border: 1px solid #aaa;
	background-color: #fff;
	border-radius: 50%;
}

.passport_type input[type="radio"] {
	display: none !important;
}

.passport_type input[type="radio"]:checked+label:after {
	content: "";
	display: block;
	position: absolute;
	top: 0px;
	left: 3px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #0d7836;
}

.passport_box {
	width: 100%;
	height: 46px;
	padding-top: 15px;
}

.tab50 div:nth-last-child(1) {
	border-bottom: 0px;
}

h4.seprate {
	font-size: 16px;
	color: #0d7836;
	padding: 15px 0 15px 0;
}

.seprate::after {
	content: "";
	border-bottom: 1px dotted #cfcfcf;
	width: 100%;
	float: left;
	padding: 2px 0;
}


/* Payment CSS */

.card_box {
	border-top: 1px solid rgba(3, 3, 3, 0.1);
	box-shadow: 0px 1px 4px rgb(3 3 3 / 14%);
	background: #fff;
	margin-bottom: 10px;
	padding: 19px 20px;
}

.box_row {
	margin-top: 10px;
	width: 100%;
	float: left;
	border: 1px solid #cacbca;
}

.mt25 {
	margin-top: 25px;
}

.mt15 {
	margin-top: 15px;
}

.mt13 {
	margin-top: 13px;
}
.mb10{margin-bottom: 10px;}
.card_box img {
	width: 32px;
	margin-right: 10px;
	vertical-align: middle;
}

.h-1 {
	min-height: 232px;
}

.card_box p {
	font-size: 14px;
	font-weight: normal;
	margin-top: 20px;
}

a {
	text-decoration: none;
	color: #000;
}

.box_row h4 {
	color: #0d7836;
}

.faq-heading {
	font-weight: 400;
	font-size: 19px;
	-webkit-transition: text-indent 0.2s;
	text-indent: 20px;
	color: #333;
	padding: 10px 0;
	position: relative;
}

.faq-text {
	font-weight: 400;
	color: #171717;
	width: 100%;
}

.faq-text input {
	margin-left: 17px;
}

.faq {
	width: 100%;
	margin: 0 auto;
	background: white;
	border-radius: 4px;
	position: relative;
	margin-top: 10px;
}

.faq-l {
	display: block;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	height: 56px;
	padding-top: 1px;
	margin-bottom: 10px;
	background-color: #FAFAFA;
	border: 1px solid #E1E1E1;
}

.faq input {
	width: auto;
	height: auto;
}

.faq-text label {
	width: auto;
	float: none;
	font-size: 14px;
	padding-left: 10px;
	cursor: pointer;
}

.gateway {
	display: none;
}

.faq .faq-arrow {
	position: absolute;
	top: 4px;
	right: 7px;
}

.faq img {
	width: 85px;
	height: auto;
}

.faq-heading input {
	width: auto;
	height: auto;
	margin-right: 14px;
}

.faq input[type="checkbox"]:checked+label {
	display: block;
	background: rgba(255, 255, 255, 255) !important;
	color: #4f7351;
	height: auto;
	transition: height 0.8s;
	-webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.faq input[type='checkbox']:not(:checked)+label {
	display: block;
	transition: height 0.8s;
	height: 56px;
	-webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.passport_fees table,
.passport_fees table tr,
.passport_fees table td {
	border: 0;
	padding: 0;
}

.passport_fees table td:nth-child(1) {
	width: 55%;
}

.passport_fees table td:nth-child(2) {
	width: 45%;
}

.passport_fees {
	background: #f1f1f1;
	padding: 10px 20px;
}

.pay-info {
	display: inline-flex;
	font-size: 14px;
	font-family: 'sfui-semibold';
}

.pay-info>* {
	margin-right: 10px;
}


/* HOME CSS */

.card_box_pass {
	border: 1px solid rgba(3, 3, 3, 0.1);
	box-shadow: 0px 1px 4px rgb(3 3 3 / 14%);
	background: #fff;
	margin-bottom: 10px;
	padding: 9px 10px;
	cursor: pointer;
}

.top_pass {
	display: flex;
	align-items: flex-start;
}

.mid-section {
	width: 100%;
	float: left;

}

.mid-section h4 {
	font-size: 22px;
}

.rows_box {
	margin-top: 30px;
	width: 100%;
	float: left;
	margin-bottom: 70px;
}

.grid_three {
	display: grid;
	grid-template-columns: 32% 32% 32%;
	grid-gap: 23px;
}

.card_box_pass img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	vertical-align: middle;
	margin-top: 8px;
}

.h-1 {
	min-height: 120px;
}

.card_box_pass span {
	font-size: 14px;
	font-weight: normal;
	width: 100%;
	float: left;
}


/* RECEIPT CSS */

.pay-recipt table,
.pay-recipt table tr,
.pay-recipt table td {
	padding: 0 10px;
}

.pay-recipt table td:first-child {
	border-left: 0;
}

.pay-recipt table {
	margin-bottom: 8px;
}

.bank-notes h5,
.client-notes h5 {
	border: 0;
	border-bottom: 1px solid #aeaeae;
	background: #f1f1f1;
	padding: 4px 10px;
}

.pay-recipt h4 {
	background: #f1f1f1;
	padding: 4px 10px;
	border: 1px solid #aeaeae;
	border-bottom: 0 !important;
}

.pay-recipt table {
	border: 1px solid #aeaeae;
}

.pay-recipt {
	max-width: 650px;
	margin: 0 auto;
	margin-top: 10px;
	width: 100%;
}

.pay-recipt table th {
	background: #f1f1f1;
	color: inherit;
}

.bank-notes,
.client-notes {
	border: 1px solid #aeaeae;
	margin-bottom: 8px;
}

.bank-notes ul,
.client-notes ul {
	padding: 0 10px;
}

.pay-recipt h3 {
	background: #f96922;
	color: #fff;
	padding: 5px 10px;
	margin-bottom: 10px;
}

.app_detail,
.pay_ack {
	padding: 0 12px;
}

.app_detail h4,
.pay_ack h4 {
	color: #0d7836;
}

.captcha {
	width: 100%;
	float: left;
	height: 90px;
}

.h_logo {
	display: none;
}

.h_logo .status_img {
	width: 75px;
}

.h_logo,
.h_logo td {
	border: 0;
}

.visa_type p {
	font-size: 25px;
	line-height: normal;
}


/* ---   instructions box --*/

.tabordion {
	color: #333;
	display: block;
	margin: auto;
	position: relative;
	width: 100%;
	height: 215px;
}

.tabordion input[name="sections"] {
	left: -9999px;
	position: absolute;
	top: -9999px;
}

.tabordion section {
	display: block;
}

.tabordion section label {
	border-right: 2px solid #0d7836;
	cursor: pointer;
	display: block;
	font-size: 16px;
	padding: 21px 20px;
	position: relative;
	width: 180px;
	float: none;
	color: #bbb;
	z-index: 99;
}

.tabordion section article {
	display: none;
	left: 230px;
	min-width: 300px;
	padding: 0 0 0 21px;
	position: absolute;
	top: 0;
}

.tabordion section article:after {
	bottom: 0;
	content: "";
	display: block;
	left: -229px;
	position: absolute;
	top: 0;
	width: 220px;
	z-index: 1;
}

.tabordion input[name="sections"]:checked+label {
	background: #eee;
	color: #000;
}

.tabordion input[name="sections"]:checked~article {
	display: block;
}


/* # The Rotating Marker #*/

details summary::-webkit-details-marker {
	display: none;
}

details summary::before {
	content: "▶";
	position: absolute;
	top: 6px;
	left: 0.8rem;
	transform: rotate(0);
	transform-origin: center;
	transition: 0.2s transform ease;
}

details[open]>summary:before {
	transform: rotate(90deg);
	transition: 0.45s transform ease;
}

details {
	box-sizing: border-box;
	margin-bottom: 15px;
	background: #fff;
	display: none;
	width: 100%;
	float: left;
	position: relative;
}

details summary {
	outline: none;
	padding: 5px;
	display: block;
	background: #f96922;
	color: white;
	padding-left: 2.2rem;
	position: relative;
	cursor: pointer;
	z-index: 10;
	border-radius: 2px;
}

details .content {
	padding: 10px;
	border: 2px solid #888;
	border-top: none;
	position: absolute;
	z-index: 1001;
	background: #fff;
	width: 100%;
	box-shadow: 0 5px 10px rgb(0 0 0 / 9%), 0 8px 7px rgb(0 0 0 / 9%);
}


/* # The Sliding Summary # */

@keyframes details-show {
	from {
		margin-bottom: -80%;
		opacity: 0;
		transform: translateY(-100%);
	}
}

details>*:not(summary) {
	animation: details-show 500ms ease-in-out;
	position: relative;
	z-index: 1;
	transition: all 0.3s ease-in-out;
	color: transparent;
	overflow: hidden;
}

details[open]>*:not(summary) {
	color: inherit;
}

details .content ul {
	list-style-type: disc;
	margin-left: 10px;
}


/* COD CSS Start */

.mt30 {
	margin-top: 30px !important;
}

.mb30 {
	margin-bottom: 30px !important;
}

.mt20 {
	margin-top: 20px;
}

.title_btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo {
	margin-top: 20px;
}

.green_row {
	background: #0d7836;
	width: 100%;
	color: #fff;
	padding-left: 7.2%;
}

.cod-Data {
	width: 100%;
	float: left;
}

.box ol {
	list-style-position: outside;
	padding-left: 20px;
}

h3.heading-green {
	color: #0D7836;
}

.box {
	border-left: 0.5px dotted #d8d8d8;
	padding: 8px 10px;
	margin-top: 15px;
}

.box:first-child {
	border-left: 0;
}

.box h4 {
	margin-bottom: 8px;
}

.tabs-container {
	position: relative;
	background: #F3F3F3;
	width: 320px;
	height: 560px;
	float: left;
	z-index: 20;
	border: 1px solid #d7d7d7;
}

.tabs-container label {
	position: relative;
	padding: 7px 10px 6px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: block;
	font-size: 13px;
	cursor: pointer;
	user-select: none;
}

.tabs-container label:hover {
	background: rgba(0, 0, 0, 0.2);
}

.tab-content {
	position: relative;
	background: #fff;
	width: calc(100% - 320px);
	height: 560px;
	float: left;
	box-sizing: border-box;
	z-index: 19;
	border: 1px solid #d7d7d7;
	border-left: 0;
	margin-bottom: 50px;
}

.tab-content:after {
	content: "";
	clear: both;
}

.tab-content .back_btn,
.tab-content .custom_btn {
	margin-top: 8px;
}

.tab-content h3 {
	background: #ebebeb;
	padding: 3px 15px;
	border-bottom: 1px solid #d7d7d7;
}

.tab-content .grid_two {
	padding: 10px 15px;
}

input[name="tab"] {
	display: none;
}

input[name="tab"]:checked+.tab-content {
	display: block;
	animation: slide 0.5s forwards;
}

.cod-Data .bk {
	background: #f1f1f1;
	padding: 25px 20px 20px;
	width: 100%;
}

.infoyn {
	width: auto;
	float: none;
	height: auto;
	padding: 0px 6px;
}
.info h3 {
    font-size: 20px;
}
.element {
	border-color: #f96922;
	-o-border-image: #f96922;
	border-image: #f96922;
	position: relative;
}

.element:before {
	content: 'i';
	background: #f96922;
	padding: 1px 11px;
	border-radius: 50%;
	position: absolute;
	top: 12px;
	left: -19px;
	border: 3px solid #fff;
	font-family: serif;
	font-weight: 700;
	color: #fff;
}

.element {
	background-clip: content-box;
	background-color: inherit;
	border-left: 6px solid #f96922;
	margin-bottom: 23px;
	padding-left: 20px;
}

.card_box_pass h4 a {
	display: flex;
	align-items: center;
}

.card_box_pass h4 a i {
	padding-left: 7px;
}

.half_w {
	width: 25%;
}


/* COD CSS End */

.blinkingText {
	font-size: 18px;

}

.highlight_txt {
	font-size: 16px;
	color: #f96922;
}

.h_txt {
	height: auto!important;
}

.common_view label,
.common_view p {
	font-size: 18px;
}

.cod-change-field th:first-child,
td:first-child {
	padding-left: 15px;
}

.iti {
	width: 100%;
}

.gateway-fee-note {
	color: red;
}

.book-appointment-note p {
	color: red;
	text-align: left;
}

.confirmbox-container p {
	overflow-wrap: break-word;
}

@media only screen and (min-width: 320px) and (max-width: 764px) {
	nav{height: 73px;}
	nav .main{padding: 0;}

	li.track_btn{display: none;}
	.main {
		width: 100%;
		padding-right: 10px;
		padding-left: 10px;
		height: auto;
		overflow-x: hidden;
		padding-bottom: 0;
	}
	.header_box span {
		position: absolute;
		top: 44px;
		padding-left: 3px;
		font-size: 13px;
		font-family: "sfui-semibold";
		width: 100%;
		float: left;
		left:10px;
	}
	.header_box {
		display: flex;
		position: relative;
	}
	.nav-links {
		margin-top: 0;
	}
	.track_application {
	    display: block;
	}
	.pay_ment thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	.pay_ment td,
	.pay_ment tr {
		width: 100%;
		float: left;
	}
	.pay_ment tr {
		display: block;
	}
	.pay_ment td {
		border-bottom: 1px solid #ddd;
		display: block;
		font-size: 14px;
		text-align: right;
		padding: 5px;
	}
	.pay_ment td::before {
		content: attr(data-label);
		float: left;
		font-weight: bold;
		text-transform: uppercase;
	}
	.pay_ment td:last-child {
		border-bottom: 0;
	}
	hr.header_line {
		height: 40px;
	}
	.top_head {
		margin-top: 0
	}
	.header_box img {
		width: 140px;
	}
	.dropdown a.custom_btn {
		position: relative;
		display: block;
		margin: 0;
	}
	.fee {
		margin-bottom: 20px;
	}
	.half_col {
		display: grid;
		grid-gap: 9px;
	}
	.line {
		padding: 0px 4px;
	}
	#progressbar li {
		font-size: 10px;
	}
	.new_app {
		bottom: 0;
	}
	.tabset {
		top: 20px;
	}
	.tabset>label {
		padding: 2px 7px;
		width: 33%;
		font-size: 12px;
	}
	.head_table {
		background: #00743d;
		color: #fff;
		padding: 0 13px;
	}
	fieldset {
		position: relative;
	}
	.dropdown-menu-top {
		padding-bottom: 0;
	}
	.grid-personal,
	.grid-two,
	.grid-container,
	.grid-payment,
	.search_grid,
	.grid-one-third {
		grid-template-columns: 100%;
	}
	.clearfix {
		display: none;
	}
	details {
		display: block;
	}
	.instruction_box {
		display: none;
	}
	.grid-container {
		grid-gap: 0;
	}
	.banner_text {
		top: 6%;
		left: 5%;
	}
	.banner-img_home {
		height: 180px;
	}
	.instruction_box {
		margin-top: 2px;
		padding: 1px 10px 5px;
	}
	.menu {
		top: -36px;
	}
	hr.header_line {
		margin: 0;

	}
	.main-bottom {
		margin-bottom: 10px;
	}
	.login_nav {
		display: flex !important;
		float: none;
	}
	.custom_btn,
	.btn {
		padding: 7px 7px !important;
		margin: 10px 0;
	}
	.save_btn {
		padding: 7px 7px !important;
		margin: 10px 7px;
	}
	.back_btn {
		padding: 7px 7px !important;
	}
	table {
		height: auto;
		border: 0;
	}
	.grid-two-box {
		margin-bottom: 0;
	}
	.grid-two,
	.grid-personal {
		grid-gap: 0;
		position: relative;
	}
	.view-buttons {
		margin-bottom: 60px;
	}
	.cus_row {
		margin: 0;
		width: 100%;
		float: left;
	}
	.cus_row input {
		margin: 0 !important
	}
	.faq-text span {
		width: 100%;
		float: left;
	}
	.pay-recipt {
		width: 100%;
	}
	.user_type ul li label {
		padding: 17px 20px 5px;
	}
	.user_type ul li .check {
		top: 20px;
		left: 0;
	}
	.shop-cart .custom_btn {
		padding: 7px 15px !important;
	}
	.main1 {
		width: 100%;
		padding-right: 20px;
		padding-left: 20px;
		height: auto;
	}
	.grid-box {
		text-align: left;
	}
	.pay_input input {
		font-size: 12px;
		padding-top: 4px;
	}
	.fix_height {
		height: auto;
	}
	.cart-info {
		height: 67px;
		overflow-y: auto;
		margin-bottom: 15px;
	}
	.dropdown-menu-top li a {
		color: #000;
	}
	.card {
		width: 100%;
		padding: 20px 15px;
	}
	.confirmbox-container {
		width: 95% !important;
	}
	.nav-links {
		padding: 0px 4.3vw;
	}
	.confirmbox-container p {
		padding: 13px !important;
	}
	.modal-content {
		height: auto;
	}
	.profile_view li {
		padding-left: 0;
	}
	.profile_view ul {
		grid-template-columns: 100%;
	}
	.fr1 {
		float: none;
	}
	.grid_detail {
		grid-template-columns: 100%;
		grid-gap: 0;
	}
	.grid_detail-box {
		padding: 0;
	}
	.grid_detail-box:first-child {
		border-right: 0;
	}
	.grid-modal-container {
		grid-template-columns: 37% 36% 18%;
		grid-gap: 10px;
	}
	.user_type,
	.profile_type {
		width: 100%;
		margin-bottom: 90px;
	}
	.grid-modal-box .btn {
		margin: 20px 0 !important;
	}
	.profile_type .form_box {
		height: 75px;
	}
	.profile_type .custom_btn {
		margin: 10px 5px;
	}
	footer {
		position: unset;
	}
	fieldset.plr10 {
		padding: 0px 8px;
	}
	#msform {
		padding: 8px 7px;
	}
	/* New CSS Added by GS */
	.grid_three {
		grid-template-columns: 100%;
		grid-gap: 13px;
	}
	.card_box_pass {
		margin-bottom: 8px;
		padding: 9px 4px;
	}
	.card_box_pass a {
		font-size: 13px;
	}
	.h-1 {
		min-height: auto;
	}
	.radio label {
		margin-bottom: 15px;
	}
	.mt15 {
		margin-top: 0;
	}
	h4.seprate {
		padding: 15px 0 0;
	}
	.common_view {
		flex-direction: column;
	}
	.common_view label {
		width: auto !important;
	}
	.common_view p {
		width: auto !important;
		float: right !important;
		margin-right: 0 !important;
	}
	.visa_type p {
		Font-size: 20px;
	}
	.sr-p-info p {
		margin: 5px 0 0;
		line-height: 17px;
	}
	.tab50 div {
		padding: 2px 6px;
	}
	.sr-p-info label {
		line-height: 14px;
	}
	.half_col_box {
		width: 100%;
	}
	.modal-footer .back_btn {
		margin-left: 8px;
	}
	.modal-body {
		padding: 0;
	}
	.notice_modal {
		max-width: 95% !important;
	}
	.notice_modal .modal-body {
		padding: 6px;
	}
	.faq {
		margin: 10px;
	}
	table.pay_ment {
		border: 1px solid #dfdfdf;
	}
	footer img {
		width: 70px;
		height: 39px;
	}
	.h_txt {
		height: 135px;
	}
	.sr-p-info div {
		padding: 5px 5px;
	}
	.box_row h4 {
		padding-left: 0px;
	}
	.main-footer {
		padding-right: 2px;
		padding-left: 2px;
		width: 100%;
	}
	.footer_flex p {
		font-size: 0.83em;
	}
	.tabordion {
		height: 450px;
	}
	.tabordion section {
		display: inline-block;
	}
	.tabordion section label {
		font-size: 14px;
		width: 90px;
		padding: 5px 15px;
	}
	.tabordion section article {
		left: 0;
		min-width: auto;
		top: auto;
	}
	.form_box {
		height: 73px;
	}
	.tabs-container,
	.tab-content {
		width: 100%;
		height: auto;
	}
	.tab-content {
		border-left: 1px solid #d7d7d7;
	}
	table th {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px
	}
	table tr {
		border-bottom: 1px solid #0d7836;
		display: table
	}
	table td,
	table tr {
		width: 100%;
		float: left
	}
	table td {
		border-bottom: 1px solid #ddd;
		display: block;
		text-align: right;
		padding: 5px 12px;
		border-left: 0;
	}
	table td:before {
		content: attr(data-label);
		float: left;
		font-family: sfui-semibold;
		text-transform: capitalize
	}
	.passport_fees table {
		margin-bottom: 0;
	}
	.passport_fees table tr td {
		border-bottom: 0 !important;
		font-size: 0.85em !important;
		line-height: 1;
	}
	.upper_bar,
	.common_view {
		justify-content: flex-start;
		padding: 0 5px;
	}
	.common_view label,
	.common_view p {
		font-size: 15px;
		padding-bottom: 5px;
	}
	.passport_fees {
		padding: 4px 7px;
	}
	.passport_fees table td,
	.pay-recipt_table tr td,
	.pageWrapper table tr td {
		width: auto;
		text-align: left;
		border-bottom: 1px solid #d7d7d7 !important;
	}
	.passport_fees table td:nth-child(even),
	.pay-recipt_table tr td:nth-child(even) {
		float: right;
	}
	.info h3 {
		font-size: 14px;
		line-height: 1.2;
	}
	.half_w {
		width: 100%;
	}
	table.pay-recipt_table td {
		border-bottom: 0 !important;
		font-size: 0.87em;
	}
	table.pay-recipt_table tr td:last-child {text-align: right;}
	.cod-Data h2 {
		font-size: 20px;
	}
	.detail_change_view {
		padding: 0;
	}
	.none_dis{display: none;}
	.margin-top{margin-top: 0 !important;}
}

@media only screen and (min-width: 765px) and (max-width: 1024px) {
	.main,
	.main-footer {
		width: 100%;
		padding-right: 30px;
		padding-left: 30px;
	}
	.top_head {
		padding: 5px 20px;
	}
	.menu ul li {
		margin-left: 24px;
		margin-top: 10px;
	}
	.nav-links {
		display: block;
		list-style: none;
		width: auto;
		padding: 0;
		text-transform: capitalize;
	}
	.instruction_box {
		padding: 15px;
		font-size: 12px;
	}
	.main1 {
		width: 100%;
		padding-right: 40px;
		padding-left: 40px;
	}
	.faq {
		padding-left: 20px;
	}
	.box_row h4 {
		padding-left: 20px;
	}
	.passport_fees h4 {
		padding: 10px 0;
	}
	.grid-two,
	.grid-payment {
		grid-template-columns: 100%;
	}
	.cart-info {
		height: auto;
	}
	.grid-two.tab_screen {
		grid-template-columns: 49% 49%;
	}
	.notice_modal {
		max-width: 85% !important;
	}
	.grid_three {
		grid-template-columns: 49% 49%;
		grid-gap: 15px;
	}
	.confirmbox-container {
		width: 85% !important;
	}
	.grid-date {
		grid-template-columns: 20% 50% 20%;
	}
	.cod-Data .bk {
		padding: 10px 10px 0;
	}
	.tabordion {
		height: 320px;
	}
	.tabs-container {
		width: 180px;
		height: 575px
	}
	.tab-content {
		width: calc(100% - 180px);
		height: 575px;
	}
	.tab-content .grid-two {
		grid-template-columns: 49% 49% !important;
	}
	.common_view label,
	.common_view p {
		font-size: 0.90em;
	}
}

@media print {
	@page {
		size: auto;
		margin: 0mm;
	}
}
